
import { defineComponent, ref } from "vue";
import _ from "lodash";
import { AxiosResponse } from "axios";
import accountService from "@/services/account.service";
import { SelectOption } from "@/common/FormTools";
import { AccountDetailsResponse } from "@/models/AccountDetailsResponse";
import { updateCustomerIdHeader } from "@/axios";
import { EventBus } from "@/common/Tools";

type CustomerPickerState = {
  customers: SelectOption[];
  selectedCustomer: number;
};

type CustomerPickerMethods = {
  extractSubcustomers: (
    response: AxiosResponse<AccountDetailsResponse>
  ) => void;
  handleCustomerChange: () => void;
  handleCustomerChangeEvent: (customerId: unknown) => void;
  checkSelectedCustomer: () => void;
};

export default defineComponent({
  name: "CustomerPicker",
  emits: ["refreshView"],
  setup(props, context) {
    const methods = {} as CustomerPickerMethods;
    const state = ref<CustomerPickerState>({
      customers: [],
      selectedCustomer: 0,
    });

    methods.extractSubcustomers = (
      response: AxiosResponse<AccountDetailsResponse>
    ) => {
      state.value.customers = _.reduce(
        _.get(response, "data.sub_customers", []),
        (
          customers: SelectOption[],
          subcustomer: AccountDetailsResponse,
          index: number
        ): SelectOption[] => {
          customers.push({
            label: _.get(
              subcustomer,
              "company_name",
              `Subcustomer #${index + 1}`
            ),
            value: _.get(subcustomer, "id", 0),
          });
          return customers;
        },
        [
          {
            label: _.get(response, "data.company_name", "Current Customer"),
            value: 0,
          },
          // {
          //   label: "All customers",
          //   value: -1,
          // },
        ] as SelectOption[]
      ) as SelectOption[];
    };

    methods.handleCustomerChange = () => {
      updateCustomerIdHeader(state.value.selectedCustomer);
      methods.checkSelectedCustomer();
      context.emit("refreshView");
    };

    methods.handleCustomerChangeEvent = (customerId) => {
      if (customerId) {
        state.value.selectedCustomer = customerId as number;
        updateCustomerIdHeader(customerId as number);
        methods.checkSelectedCustomer();
      }
    };

    methods.checkSelectedCustomer = () => {
      EventBus.emit("selectedCustomer", state.value.selectedCustomer);
    };

    EventBus.on("changeCustomer", methods.handleCustomerChangeEvent);

    EventBus.on("checkSelectedCustomer", methods.checkSelectedCustomer);

    accountService
      .getAccountDetails()
      .then(methods.extractSubcustomers)
      .catch(() => {
        state.value.customers = [];
      });
    return {
      state,
      methods,
    };
  },
});
