
import { defineComponent } from "vue";
import Spinner from "@/components/ui/Spinner.vue";
import Icon from "@/components/ui/Icon.vue";

export default defineComponent({
  name: "DefaultButton",
  components: { Icon, Spinner },
  props: {
    type: {
      type: String,
      default: "default",
    },
    buttonRole: {
      type: String,
      default: "button",
    },
    role: {
      type: String,
      default: "primary",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: "",
    },
    injectedClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    buttonClass(): string {
      switch (this.type) {
        case "outline":
          return "default-button--outline";
        case "empty":
          return "default-button--empty";
        default:
          return "";
      }
    },
    buttonRoleClass(): string {
      switch (this.role) {
        case "danger":
          return "default-button--danger";
        default:
          return "";
      }
    },
  },
});
