import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fff11518"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative flex flex-row min-h-screen justify-end" }
const _hoisted_2 = { class: "absolute right-20 top-12" }
const _hoisted_3 = { class: "wrapper w-full min-h-screen md:w-4/5" }
const _hoisted_4 = { class: "text-center text-medium mb-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomerPicker = _resolveComponent("CustomerPicker")!
  const _component_nav_sidebar = _resolveComponent("nav-sidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CustomerPicker, { onRefreshView: _ctx.refreshView }, null, 8, ["onRefreshView"])
    ]),
    _createVNode(_component_nav_sidebar, {
      show: _ctx.showNavigation,
      onLogoutClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showLogoutModal = true)),
      onOverlayClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showNavigation = false))
    }, null, 8, ["show"]),
    _createElementVNode("div", {
      class: _normalizeClass(["button-overlay block md:hidden", _ctx.showNavigation ? 'w-2/5' : 'w-full'])
    }, [
      _createElementVNode("button", {
        class: "show-nav-button shadow-2xl",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showNavigation = !_ctx.showNavigation))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(["icon", _ctx.showNavigation ? 'icon-cross' : 'icon-menu'])
        }, null, 2)
      ])
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(), _createBlock(_component_router_view, { key: _ctx.viewTimestamp })),
      _createVNode(_component_modal, {
        type: "warning",
        size: "small",
        modelValue: _ctx.showLogoutModal,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showLogoutModal) = $event)),
        onConfirmClick: _ctx.handleLogoutConfirmClick
      }, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("auth.logout_modal.title")), 1)
        ]),
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("auth.logout_modal.content")), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "onConfirmClick"])
    ])
  ]))
}