import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-343afe78"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["default-button flex", {
      [_ctx.injectedClass]: _ctx.injectedClass,
      'py-4 px-4': !_ctx.injectedClass,
      [_ctx.buttonClass]: _ctx.buttonClass,
      [_ctx.buttonRoleClass]: _ctx.buttonRoleClass,
    }]),
    type: _ctx.buttonRole,
    disabled: _ctx.isLoading || _ctx.disabled
  }, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_spinner, {
          key: 0,
          class: "w-5"
        }))
      : _createCommentVNode("", true),
    (!_ctx.isLoading && _ctx.iconName)
      ? (_openBlock(), _createBlock(_component_icon, {
          key: 1,
          name: _ctx.iconName
        }, null, 8, ["name"]))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? _renderSlot(_ctx.$slots, "default", { key: 2 }, undefined, true)
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}