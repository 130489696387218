import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["value", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.state.customers.length > 1)
    ? _withDirectives((_openBlock(), _createElementBlock("select", {
        key: 0,
        class: "h-10 border border-primary-blue text-primary-blue rounded bg-white px-4 py-1",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.selectedCustomer) = $event)),
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.methods.handleCustomerChange && _ctx.methods.handleCustomerChange(...args)))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.customers, (customer, index) => {
          return (_openBlock(), _createElementBlock("option", {
            value: customer.value,
            key: `customer_${index}_${new Date().getTime()}`,
            innerHTML: customer.label
          }, null, 8, _hoisted_1))
        }), 128))
      ], 544)), [
        [_vModelSelect, _ctx.state.selectedCustomer]
      ])
    : _createCommentVNode("", true)
}