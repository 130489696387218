
import { defineComponent, inject } from "vue";
import NavSidebar from "@/components/layout/NavSidebar.vue";
import Modal from "@/components/ui/Modal.vue";
import Keycloak from "keycloak-js";
import CustomerPicker from "@/components/ui/CustomerPicker.vue";

export default defineComponent({
  name: "Dashboard",
  components: { CustomerPicker, Modal, NavSidebar },
  setup() {
    return {
      keycloak: inject("keycloak") as Keycloak.KeycloakInstance,
    };
  },
  data() {
    return {
      showNavigation: 900 < window.innerHeight,
      showLogoutModal: false,
      viewTimestamp: new Date().getTime(),
    };
  },
  methods: {
    handleLogoutConfirmClick(): void {
      this.keycloak.logout();
    },
    refreshView(): void {
      this.viewTimestamp = new Date().getTime();
    },
  },
});
