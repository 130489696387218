
import { defineComponent } from "vue";
import DefaultButton from "@/components/ui/DefaultButton.vue";

export default defineComponent({
  name: "Modal",
  components: { DefaultButton },
  emits: ["confirmClick", "cancelClick", "update:modelValue"],
  props: {
    modelValue: {
      type: Boolean,
    },
    buttonsPosition: {
      type: String,
      default: "left",
    },
    type: {
      type: String,
      default: "",
    },
    hideConfirmButton: {
      type: Boolean,
      default: false,
    },
    hideCancelButton: {
      type: Boolean,
      default: false,
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "",
    },
    blockConfirm: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleConfirmClick(): void {
      this.$emit("confirmClick");
      this.$emit("update:modelValue", false);
    },
    close(): void {
      this.$emit("update:modelValue", false);
      this.$emit("cancelClick");
    },
  },
  computed: {
    buttonsClass(): string {
      switch (this.buttonsPosition) {
        case "right":
          return "modal__buttons--right";
        default:
          return "modal__buttons--left";
      }
    },
    modalTypeClass(): string {
      switch (this.type) {
        case "warning":
          return "modal--warning";
        case "confirm":
          return "modal--confirm";
        default:
          return "";
      }
    },
    modalSize(): string {
      switch (this.size) {
        case "big":
          return "modal--big";
        case "small":
          return "modal--small";
        default:
          return "";
      }
    },
  },
});
